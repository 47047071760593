import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/common/Button';
import { ImageCard } from '../components/common/Card';
import { Hint } from '../components/common/Hint';
import { Loading } from '../components/common/Loading';
import { Page } from '../components/common/Page';
import { Stack } from '../components/common/Stack';
import { ToggleFilter } from '../components/common/ToggleFilter';
import { InviteUsers } from '../components/InviteUsers';
import { BuildingsMap } from '../components/map/BuildingsMap';
import { replaceMapKey } from '../components/map/placeUtils';
import { useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';
import { fontSize, fontWeight, space } from '../tokens';
import { useUser } from '../UserProvider';
import { Building } from '../utils/appApi';

export const Title = styled.div`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.base};
  line-height: 1;
  padding: ${space[2]} 0 ${space[2]} 0;
`;

const renderBuildingCard = (
  building: any,
  allIsActive: boolean,
  t: any,
  replaceMapKey: (key: any) => string | undefined
) => {
  return allIsActive || building.isFavorite ? (
    <ImageCard
      key={building.id}
      to={`/building/${building.id}/`}
      title={building.name || `${t.building} ${building.id}`}
      image={replaceMapKey(building.photo) || ''}
    >
      {`${building.street} ${building.streetNumber}`}
    </ImageCard>
  ) : null;
};

export function Buildings() {
  const t = useTranslation();
  const user = useUser();
  const query = useApiQuery('getBuildings');

  const hasFavorites = query.data?.some((building: any) => building.isFavorite);

  const [allIsActive, setAllIsActive] = useState<boolean>(true);
  const [visibleBuildings, setVisibleBuildings] = useState<Building[]>([]);

  const buildingsWithoutValidAddress =
    query.data?.filter(
      (building: any) =>
        building.latitude === null && building.longitude === null
    ) || [];

  return (
    <Page pageTitle={t.buildings}>
      <ToggleFilter
        leftIsActive={allIsActive}
        setLeftIsActive={setAllIsActive}
        leftToggleText={t.all}
        rightToggleText={t.favorites}
      />
      <Stack gap={space[4]}>
        {query.isLoading && <Loading />}
        {query.isSuccess && (
          <>
            {query.data.length > 0 && (
              <BuildingsMap
                buildings={query.data}
                allIsActive={allIsActive}
                onVisibleBuildingsChange={setVisibleBuildings}
              />
            )}
            <Stack gap={space[2]}>
              {visibleBuildings.length > 0 ? (
                visibleBuildings.map((building: any) =>
                  renderBuildingCard(building, allIsActive, t, replaceMapKey)
                )
              ) : buildingsWithoutValidAddress.length > 0 &&
                visibleBuildings.length > 0 ? (
                <Hint>{t.noBuildingsVisible}</Hint>
              ) : null}
              {hasFavorites || allIsActive ? null : (
                <Hint>{t.noFavoritesChoosen}</Hint>
              )}
            </Stack>
            {buildingsWithoutValidAddress?.length > 0 && (
              <>
                <Title>{t.buildingsWithoutValidAddress}</Title>
                {buildingsWithoutValidAddress.map((building: any) =>
                  renderBuildingCard(building, allIsActive, t, replaceMapKey)
                )}
              </>
            )}
            <Button as={Link} to="/building" variant="primary">
              {t.addBuilding}
            </Button>
          </>
        )}
        {(user?.role === 'manager' || user?.role === 'admin') && (
          <InviteUsers />
        )}
      </Stack>
    </Page>
  );
}
