import React from 'react';
import styled from 'styled-components';

import {
  Content,
  IconProps,
  StyledCard,
  Title,
} from '../../components/common/Card';
import {
  MandatoryIcon,
  OkIcon,
  RecommendedIcon,
} from '../../components/common/Icons';
import { Stack } from '../../components/common/Stack';
import { CardSubTitle, Overline } from '../../components/common/typography';
import { borderRadius, color, space } from '../../tokens';

const Icon = styled.div<{
  selectedColor?: string;
}>`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.selectedColor === 'warning'
      ? color.warning
      : props.selectedColor === 'error'
      ? color.error
      : color.success};
  color: ${color.textOnPrimary};
  padding: ${space['2']};
  border-radius: 0 ${borderRadius.default} ${borderRadius.default} 0;
`;

const FloorIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FloorValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FloorColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FloorColumnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const FloorSeparator = styled.div`
  margin: 0 10px;
`;

interface FloorProps extends IconProps {
  highestPriority?: string;
  floorStatistics: {
    mandatory: number;
    recommended: number;
    ok: number;
  };
}

export function FloorCard({
  icon,
  title,
  subTitle,
  to,
  disabled,
  status,
  children,
  highestPriority,
  floorStatistics,
  ...rest
}: FloorProps) {
  const iconColor =
    highestPriority === 'mandatory'
      ? 'error'
      : highestPriority === 'recommended'
      ? 'warning'
      : 'success';

  const { mandatory, recommended, ok } = floorStatistics;

  return (
    <StyledCard to={to} disabled={disabled} status={status} {...rest}>
      <Content>
        <Stack gap={space[2]}>
          <Title>
            {title}
            {subTitle && <CardSubTitle>{subTitle}</CardSubTitle>}
          </Title>
          <Overline>{children}</Overline>
        </Stack>
      </Content>
      <FloorColumnContainer>
        <FloorColumn>
          <FloorIconContainer>
            <MandatoryIcon color={color.error} />
          </FloorIconContainer>
          <FloorValueContainer>{mandatory}</FloorValueContainer>
        </FloorColumn>
        <FloorSeparator />
        <FloorColumn>
          <FloorIconContainer>
            <RecommendedIcon color={color.warning} />
          </FloorIconContainer>
          <FloorValueContainer>{recommended}</FloorValueContainer>
        </FloorColumn>
        <FloorSeparator />
        <FloorColumn>
          <FloorIconContainer>
            <OkIcon color={color.success} />
          </FloorIconContainer>
          <FloorValueContainer>{ok}</FloorValueContainer>
        </FloorColumn>
      </FloorColumnContainer>
      {icon && <Icon selectedColor={iconColor}>{icon}</Icon>}
    </StyledCard>
  );
}
