import React from 'react';
import { useParams } from 'react-router-dom';

import { Card } from '../components/common/Card';
import {
  BoxIcon,
  LicenseIcon,
  MailIcon,
  UserIcon,
} from '../components/common/Icons';
import { Page } from '../components/common/Page';
import { Stack } from '../components/common/Stack';
import { useApiQuery } from '../hooks/useApi';

export function AdminCompany() {
  const params = useParams();
  const companyId = parseInt(params.id!);

  const company = useApiQuery('getCompany', companyId);
  return (
    <Page header="Admin" backLink="/admin" pageTitle={company.data?.name}>
      <Stack>
        <Card to="users" icon={<UserIcon />} title="Users">
          View and manage company users.
        </Card>
        <Card to="boxes" icon={<BoxIcon />} title="Boxes">
          View the company's ELEVATORSENSE boxes.
        </Card>
        <Card to="licenses" icon={<LicenseIcon />} title="Licenses">
          View the company's purchased licenses.
        </Card>
        <Card to="invites" icon={<MailIcon />} title="Invites">
          View the company's invites.
        </Card>
      </Stack>
    </Page>
  );
}
