import React from 'react';

import { Card } from '../../components/common/Card';
import { ChevronRightIcon } from '../../components/common/Icons';
import { useTranslation } from '../../I18nProvider';
import { DriveParameterValue } from '../../utils/programming/BoxApi';
import { ParameterKeys } from '../../utils/programming/parameterKeyMap';

type DriveParameterCardProps = {
  keys: ParameterKeys;
  param: DriveParameterValue;
  disabled?: boolean;
};
export function DriveParameterCard({
  keys,
  param,
  disabled = false,
}: DriveParameterCardProps) {
  const t = useTranslation();

  const unit = param.unit !== 'None' ? ` ${param.unit}` : '';
  const subTitle = `${param.value}${unit}`;
  const icon = !disabled && <ChevronRightIcon />;
  const url = !disabled ? `/programming/${keys.id}` : '';

  return (
    <Card title={t[keys.title]} subTitle={subTitle} icon={icon} to={url}>
      {keys.text && t[keys.text]}
    </Card>
  );
}
