import React from 'react';

import { Button } from '../components/common/Button';
import { Card } from '../components/common/Card';
import { ChevronRightIcon } from '../components/common/Icons';
import { Page } from '../components/common/Page';
import { Stack } from '../components/common/Stack';
import { CardTitle } from '../components/common/typography';
import { useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';

export function Admin() {
  const companies = useApiQuery('getCompanies');
  const t = useTranslation();

  const VITE_ENABLE_ERROR_BUTTON =
    import.meta.env.VITE_ENABLE_ERROR_BUTTON?.toLowerCase() === 'true';

  const createError = () => {
    throw new Error('Error created with button on admin page');
  };

  return (
    <Page header="Admin" backLink="/">
      <Stack>
        <CardTitle>Customers</CardTitle>
        {companies.data?.map((company) => (
          <Card
            title={company.name}
            key={company.id}
            to={`/admin/${company.id}`}
            icon={<ChevronRightIcon />}
          >
            {company.customerRef}
          </Card>
        ))}
        <Button
          onClick={async () => {
            const res = await fetch('/api/app/admin/export/licenses', {
              headers: {
                authorization: localStorage.getItem('token')!,
              },
            });
            const blob = await res.blob();
            const url = URL.createObjectURL(blob);
            location.assign(url);
          }}
        >
          {t.downloadLicense}
        </Button>
        <Button
          onClick={async () => {
            const res = await fetch(
              '/api/app/admin/export/extendedWarrantyRequests',
              {
                headers: {
                  authorization: localStorage.getItem('token')!,
                },
              }
            );
            const blob = await res.blob();
            const url = URL.createObjectURL(blob);
            location.assign(url);
          }}
        >
          {t.downloadRequestedExtendedWarranty}
        </Button>
        {VITE_ENABLE_ERROR_BUTTON && (
          <Button onClick={createError} size="small">
            Create Error
          </Button>
        )}
      </Stack>
    </Page>
  );
}
