import React, { Fragment, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { IconButton } from '../../components/common/IconButton';
import { ChevronRightIcon, EditIcon } from '../../components/common/Icons';
import { Page } from '../../components/common/Page';
import { Spacer } from '../../components/common/Spacer';
import { Stack } from '../../components/common/Stack';
import { Dropdown } from '../../components/form/Dropdown';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import {
  FloorOverview,
  FloorStatistics,
  getErrorMessage,
  isStatus,
  Priorities,
} from '../../utils/api';
import { ActivateTrial } from './ActivateTrial';
import { DoorModelNotSupported } from './DoorModelNotSupported';
import { FloorCard } from './FloorCard';
import { IncompleteElevatorSettings } from './IncompleteElevatorSettings';
import { PriorityGrid } from './PriorityGrid';
import { PurchaseLicense } from './PurchaseLicense';
import { ServiceUnavailable } from './ServiceUnavailable';

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

type FloorStatisticsWithType = Omit<FloorStatistics, 'floor'> & {
  floor: number;
  type: string;
};

const getMaxPriority = (priorities: Priorities) => {
  return priorities.mandatory > 0
    ? 'mandatory'
    : priorities.recommended > 0
    ? 'recommended'
    : 'ok';
};

export function Overview() {
  const t = useTranslation();
  const [sorting, setSorting] = useState('bottomToTop');

  // there is not date picker in the first version
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());

  const [overAllStatistics, setOverAllStatistics] = useState<
    Record<string, number>
  >({});

  const [floorStatistics, setFloorStatistics] = useState<
    FloorStatisticsWithType[]
  >([]);

  const params = useParams();
  const id = parseInt(params.id!);
  const buildingId = parseInt(params.buildingId!);
  const query = useApiQuery(
    {
      method: 'getFloorOverview',
      useErrorBoundary: false,
      onSuccess: (data: FloorOverview) => {
        setOverAllStatistics({
          [t.mandatory]: data.overallStatistics.mandatory,
          [t.recommended]: data.overallStatistics.recommended,
          [t.ok]: data.overallStatistics.ok,
        });
        setFloorStatistics(
          data.floorStatistics.map((el: FloorStatistics) => {
            const type = el.floor === 'cardoor' ? 'cardoor' : 'floor';
            // REVISIT: Maybe we have a better idea in the future how to improve the sorting
            const floor = type === 'cardoor' ? -1000 : Number(el.floor);
            return { ...el, type, floor };
          })
        );
      },
    },
    id,
    selectedDate
  );

  const errorMessage = getErrorMessage(query.error);
  const isLicenseInvalid = isStatus(query.error, 403);
  const isTrialEnabled = isStatus(query.error, 402);
  const isElevatorSettingsIncomplete =
    isStatus(query.error, 422) &&
    errorMessage ===
      'The elevator settings are incomplete for preventive maintenance';
  const isDoorModelUnsupported =
    isStatus(query.error, 422) &&
    errorMessage === 'Door model is not supported';
  const notAllPartsSelected =
    isStatus(query.error, 422) &&
    errorMessage === 'Not all component parts have been selected';

  const linkTo = isElevatorSettingsIncomplete
    ? '../settings'
    : notAllPartsSelected
    ? '../settings/component-details'
    : '../settings';

  const isServiceUnavailable = isStatus(query.error, 503);
  if (isStatus(query.error, 500)) {
    throw query.error;
  }

  const sortingOptions = [
    { value: 'bottomToTop', label: t.bottomToTop },
    { value: 'topToBottom', label: t.topToBottom },
    { value: 'priority', label: t.priority },
  ];

  if (floorStatistics.length !== 0) {
    let fn = (a: FloorStatisticsWithType, b: FloorStatisticsWithType) =>
      a.floor - b.floor;
    if (sorting === 'topToBottom') {
      fn = (a, b) => b.floor - a.floor;
    } else if (sorting === 'priority') {
      fn = (a, b) => {
        const aPriorityValues = Object.values(a.priorities);
        const bPriorityValues = Object.values(b.priorities);

        const aPrioritySum = aPriorityValues.reduce(
          (sum: number, value: any) => sum + value,
          0
        );
        const bPrioritySum = bPriorityValues.reduce(
          (sum: number, value: any) => sum + value,
          0
        );

        return bPrioritySum - aPrioritySum;
      };
    }
    floorStatistics.sort(fn);
  }

  return (
    <Page
      header={
        <LiftHeader
          id={id}
          action={
            <IconButton as={Link} to="./../settings">
              <EditIcon />
            </IconButton>
          }
        />
      }
      pageTitle={t.preventiveMaintenance}
      backLink="../../"
      subHeadline={t.planningAndAlerts}
      // nextLink={
      //   // WIC-598
      //   isLicenseInvalid ? null : (
      //     <IconButton as={Link} to="../spart-parts-list">
      //       <ListIcon />
      //     </IconButton>
      //   )
      // }
    >
      <Stack>
        {query.isLoading && <ServiceUnavailable showButtons={false} />}
        {isDoorModelUnsupported && <DoorModelNotSupported />}
        {isLicenseInvalid && <PurchaseLicense />}
        {isTrialEnabled && (
          <ActivateTrial liftId={id} onActivate={query.refetch} />
        )}
        {(isElevatorSettingsIncomplete || notAllPartsSelected) && (
          <IncompleteElevatorSettings linkTo={linkTo} />
        )}
        {isServiceUnavailable && <ServiceUnavailable />}
        {query.isSuccess && (
          <OverviewContainer>
            <PriorityGrid data={overAllStatistics} />
            <Spacer />
            <Dropdown
              label={t.sortBy}
              value={sorting}
              onChange={(e) => setSorting(e.target.value)}
            >
              {sortingOptions.map((sortingOption) => (
                <option value={sortingOption.value} key={sortingOption.value}>
                  {sortingOption.label}
                </option>
              ))}
            </Dropdown>
            <Spacer />
            {floorStatistics.map((sortedFloor) => (
              <Fragment
                key={
                  sortedFloor.type === 'cardoor' ? 'cardoor' : sortedFloor.floor
                }
              >
                <FloorCard
                  title={
                    sortedFloor.type === 'cardoor'
                      ? t.carDoor
                      : t.floor + ' ' + sortedFloor.floor
                  }
                  to={
                    sortedFloor.type === 'cardoor'
                      ? 'cardoor'
                      : `${sortedFloor.floor}`
                  }
                  icon={<ChevronRightIcon />}
                  highestPriority={getMaxPriority(sortedFloor.priorities)}
                  floorStatistics={sortedFloor.priorities}
                >
                  <></>
                </FloorCard>
                <Spacer />
              </Fragment>
            ))}
          </OverviewContainer>
        )}
      </Stack>
    </Page>
  );
}
