import React from 'react';

import { Translations } from './en';

export const es: Translations = {
  aborted: 'Interrumpido',
  accelerationDescription:
    'Factor de aceleración que se utiliza para el cálculo de la curva.',
  accelerationNote:
    'Este factor tiene que ser lo suficientemente alto para alcanzar las velocidades ajustadas, de lo contrario la puerta no alcanzará la velocidad deseada.',
  accelerationTitle: 'Aceleración',
  acceptTerms: 'Acepto las condiciones de uso',
  add: 'Añadir',
  addBuilding: 'Añadir edificio',
  addLift: 'Añadir Ascensor',
  addressHint:
    'Introduce el nombre de una empresa o edificio para recibir sugerencias de direcciones e imágenes.',
  adjustCameraSettings:
    'También puede ajustar la configuración de la cámara en el menú desplegable.',
  admin: 'Admin',
  all: 'Todo',
  allBuildingsSubscription: 'De todos los edificios',
  alphabetical: 'Alfabético',
  ambientTemperature: 'Temperatura de la caja',
  assetNumber: 'ID ascensor',
  assetNumberHint:
    'Introduce el ID (número aka instalación/equipamiento) del ascensor si lo tienes a mano.',
  auto: 'Auto',
  back: 'Atrás',
  backToDashboard: 'Volver al Dashboard',
  belt_tension: 'Tensión de la correa',
  belt_tension_actions:
    'Ajusta la tensión de la correa como se describe en el manual de instalación.',
  beltclamp: 'Abrazadera',
  belt_tension_description: 'La tensión de la correa es demasiado alta/baja.',
  bottom: 'abajo',
  bottomToTop: 'Abajo hacia arriba',
  boxLinkedToOtherLift: (lift: string, building: string) =>
    'La caja está vinculada al ${ascensor} en el ${edificio}. ¿Continuar?',
  boxNotReady: 'La caja seleccionada no está lista.',
  boxOfflineHint:
    'Si desea actualizar una de las cajas que se enumeran a continuación, primero conéctelo a Internet.',
  boxScannedNoIdFound: 'Caja escaneada, pero no se encontró ningún ID.',
  boxSoftware: 'Software',
  boxSoftwareStatusError:
    'No se pudo recuperar el estado de las cajas: por favor, revise la conexión a internet de la caja e inténtelo de nuevo. Si el problema persiste, póngase en contacto con su representante de Wittur.',
  boxSoftwareTitle: 'Software de la caja',
  boxSoftwareUpdateError:
    'Error al actualizar el software de la caja: por favor, revise la conexión a internet de la caja e inténtelo de nuevo. Si el problema persiste, póngase en contacto con su representante de Wittur.',
  box_id: 'ID caja',
  brakeParDescription:
    'Mediante el ajuste de este parámetro se influye en el retardo y el arrastre al final de apertura/cierre (preajustado en ‘5’).',
  brakeParNote:
    '"El rango de ajuste posible es de ""0"" a ""9"", pero el freno más rápido se alcanza con el parámetro ""0"" y el retraso más lento se alcanza con el parámetro ""9""."',
  brakeParTitle: 'Parámetro de freno',
  buffer: 'Buffer',
  building: 'Edificio',
  buildingName: 'Nombre',
  buildings: 'Edificios',
  buildingsWithoutValidAddress: 'Edificios sin dirección válida',
  bushingClose: 'Casquillo cerrado',
  bushingOpen: 'Casquillo abierto',
  cablechain: 'Guiado de cables',
  cableTensorSpring: 'Muelle tensor del cable',
  callFloorsInstruction: (
    <>
      {' '}
      Abre la puerta y <b>llama todos los demás pisos</b>. Sal de la cabina y
      haz clic en ""Continuar"".
    </>
  ),
  cameraMode: 'Modo de cámara',
  cancel: 'Cancelar',
  car: 'Cabina',
  carDoor: 'Puerta de la cabina del ascensor',
  carDoorCounter: 'Contador de ciclos de la electrónica de puerta anterior',
  carDoorModified: 'Se ha modificado la puerta de la cabina del ascensor',
  cd_counter_rollers_installation: 'Ruedas inferiores',
  cd_counter_rollers_installation_actions:
    '"""Para cada puerta de piso, comprueba que las ruedas se pueden girar fácilmente con la mano y que no estén a demasiada distancia de la guía. Si así fuera, afloja las ruedass y vuelve a ajustarlos para que estén lo más cerca posible de la guía, pero sin tocarla, y puedan girarse fácilmente con la mano. Si necesitas desmontar el patin para acceder a las ruedas, sigue las instrucciones de montaje."""',
  cd_counter_rollers_installation_description:
    'Las ruedas inferiores de la puerta de cabina no están instaladas correctamente.',
  cd_roller: 'Rueda puerta de cabina',
  chooseFloorOrCardoor: 'Elija Piso / Puerta de cabina',
  choosePartNumber: 'Elija el número de pieza',
  chooseReason: 'Elige un motivo (opcional)',
  chooseStartingFloorInstructions:
    'Seleccione el piso de inicio de la marcha de control. El piso de inicio puede ser el más alto o el más bajo.',
  clearDriveErrors: 'Borrar',
  close: 'Cerrar',
  closeDoorInstructions: (floor: string) => (
    <>
      {' '}
      Ve al piso <b>{floor}</b>. Antes de proceder, comprueba que la puerta de
      la cabina del ascensor esté cerrada.
    </>
  ),
  closeForcePotiDescription:
    'Establece el límite de fuerza utilizado en la apertura y cierre de la puerta.',
  closeForcePotiNote:
    'Ajustable mediante potenciómetro en la electrónica. En las nuevas electrónicas como MIDIV2 ajustar aquí en modo de servicio si es necesario (no hay necesidad de ajustar en aplicaciones estándar debido al autoaprendizaje).',
  closeForcePotiTitle: 'Límite de fuerza de cierre',
  closeHoldForceDescription: 'Fuerza que se aplica en el extremo cercano.',
  closeHoldForceNote:
    'Esta fuerza debe ser de entre 50N y 70N. Ten en cuenta que una fuerza demasiado baja provocará aperturas de los acopladores no deseada. Una fuerza demasiado alta calentará el motor innecesariamente y puede que funcione en modo de reducción.',
  closeHoldForceTitle: 'Fuerza de cierre final (retención)',
  closeSpeedDescription:
    'Velocidad máxima de la puerta en sentido de cierre. La curva cerrada se calcula para alcanzar esta velocidad ajustada, si es posible.',
  closeSpeedNote:
    'La velocidad real de la puerta también está relacionada con los interruptores DIP de velocidad de los accionamientos. El ajuste a velocidad 4 significa el 100% del valor 2.',
  closeSpeedTitle: 'Velocidad de cierre',
  closing_device_failure: 'Dispositivo de cierre',
  closing_device_failure_actions:
    '"""Comprueba que todos los componentes del dispositivo de cierre de la puerta del piso funcionen correctamente (spirator, muelle de cierre o contrapeso). La puerta del piso debe poder cerrarse sola desde cualquier posición."""',
  closing_device_failure_description:
    'El dispositivo de cierre no está instalado correctamente. Se requiere demasiada fuerza para cerrar la puerta.',
  completed: 'Terminado',
  componentDetails: 'Detalles del componente',
  componentDetailsHint:
    'Para algunos componentes, hay varias opciones de números de pieza que deben seleccionarse. Esta es información importante para usar la función de mantenimiento preventivo. Haga clic en los elementos enumerados para seleccionar la pieza real instalada.',
  confirm: 'Confirmar',
  confirmDeleteBuilding: '¿De verdad quieres borrar este edificio?',
  confirmDeleteErrorHistory:
    '¿Realmente desea eliminar este historial de errores?',
  confirmDeleteFavoriteBuilding:
    '¿De verdad quieres eliminar este edificio de tus favoritos?',
  confirmDeleteLift: '¿De verdad quieres borrar este ascensor?',
  continue: 'Continuar',
  couplerelease: 'Apertura de patin',
  couplerFriction: 'Fricción del patín',
  couplerMovementParameter: 'Parámetro movimiento del patín',
  couplerSpeedDescription:
    'Este valor define la velocidad máxima de la correa, en la zona de acoplador definida.',
  couplerSpeedTitle: 'Velocidad del patín',
  couplerWitdhDescription:
    'Este valor define la distancia de la correa en la zona del patín. Este valor se obtiene tras el procedimiento de aprendizaje.',
  couplerWitdhTitle: 'Ancho del patin',
  currentVersion: 'Version actual',
  couplerwidth: 'Ancho patín',
  date: 'Fecha',
  delete: 'Borrar',
  deleteErrorHistory: 'Eliminar el historial de errores',
  directConnection: (
    <>
      <p>
        {' '}
        Conéctate al WIFI de la caja ELEVATORSENSE escaneando el código QR
        impreso en la caja con la cámara de tu teléfono.
      </p>
      <p>
        {' '}
        Una vez conectado, vuelve a esta pantalla y pulsa el botón{' '}
        <b>continuar</b> de abajo.
      </p>
    </>
  ),
  disconnectProgrammingTool: 'Desconectar la programación inalámbrica',
  divpulley: 'Divpulley',
  documentation: 'Documentación',
  doorContact: 'Contacto puerta',
  doorCycleCounter: 'Contador ciclos puerta',
  doorCycleError1: 'No se han recibido datos del ciclo de puerta.',
  doorCycleError2: 'Se interrumpió el movimiento del ciclo de la puerta.',
  doorCycleError4: 'Se ha detectado una reapertura de la puerta.',
  doorCycleError5: 'Se ha detectado un nuevo cierre de la puerta.',
  doorCycleError7: 'No se han recibido ciclos de puerta válidos.',
  doorCycles: 'Ciclos de puerta',
  doorFriction: 'Fricción de la puerta',
  doorInstallationDate: 'Fecha de instalación de la puerta',
  doorIsClosed: 'Sí, la puerta está cerrada',
  doorModel: 'Modelo de puerta',
  doorModelNotSupported:
    'El modelo de puerta seleccionado no está disponible para el mantenimiento preventivo.',
  doorMovementParameter: 'Parámetro movimiento de la puerta',
  doorPosition: 'Posición de la puerta',
  doorSerialNumber: 'Número de serie de la puerta',
  doorType: 'Tipo de puerta',
  doorwidth: 'Ancho puerta',
  downloadCertificate: 'Descargar certificado',
  downloadLicense: 'Descargar todas las licencias como CSV',
  downloadRequestedExtendedWarranty:
    'Descargar todas las solicitudes de ampliación de garantía',
  drive_motor_noise: 'Ruido motor accionamiento',
  driveesn: 'ESN',
  drivesoftware: 'Firmware',
  editBuilding: 'Editar edificio',
  editLift: 'Editar ascensor',
  elecBoard: 'Tarjeta electrónica',
  elevatorIdNotFound: (
    <>
      Para solicitar una garantía ampliada, debe introducirse el número de serie
      de la puerta en los ajustes del ascensor. ajustes del ascensor.
      <b>Haga clic aquí para acceder a los ajustes.</b>
    </>
  ),
  elevatorInstallationDate: 'Fecha de instalación del ascensor',
  elevatorUsageType: 'Tipo de uso del ascensor',
  email: 'Email',
  encoderTemperature: 'Temperatura del encoder',
  error: 'Error',
  errorDescription: 'Descripción del error',
  errorEarlierThanInstallationDate:
    'No es posible ingresar una fecha anterior a la fecha de instalación.',
  errorInvalid: 'Introduce un valor válido.',
  errorInvalidOperation: 'Operación no válida',
  errorMessages: 'Mensajes de error',
  errorMessagesDescription: 'Pantalla de los mensajes de error registrados.',
  errorNotFound: 'Recurso no encontrado',
  errorRetryCamera:
    'No se puede acceder a la cámara. Reintentando, la página se recargará...',
  errorRequired: 'Este campo es obligatorio.',
  errorUnableToAccessCamera:
    'No se puede acceder a la cámara después de varios intentos. Por favor, actualice la página o revise los permisos de la cámara.',
  errorUnknownOrForbiddenBox:
    'La caja ElevatorSense no está disponible. Ponte en contacto con tu servicio Wittur si necesitas asistencia.',
  errors: 'Errores',
  errorsEmpty: 'Sin mensajes.',
  errorsEmptyHint:
    'Por favor, comprueba tus filtros y, si procede, habilita los tipos adicionales.',
  extendWarranty: 'Ampliación de garantía',
  fatalError: 'Error fatal',
  fatalErrors: 'Errores fatales',
  favorite: 'Favorito',
  favorites: 'Favoritos',
  figureAdjustment: 'Ajuste',
  figureAugustaEVO: 'Ejemplo Augusta EVO',
  figureClosingWeight: 'Contrapeso',
  figureEco: 'ECO+',
  figureHydra: 'Ejemplo Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'No OK',
  figureOk: 'OK',
  figureSGV: 'Ejemplo SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Muelle de cierre',
  firstName: 'Nombre',
  floor: 'Piso',
  floors: 'Pisos',
  followingBoxScanned: 'La caja siguiente ha sido escaneada',
  forgotPassword: '¿Has olvidado tu contraseña?',
  forgotPasswordText:
    'Introduce tu dirección de email para restablecer la contraseña.',
  groundFloor: 'Planta baja',
  home: 'Inicio',
  incompleteElevatorSettings:
    'Para utilizar el mantenimiento preventivo, necesitamos más información sobre este ascensor. Vaya a la configuración de su ascensor y complete la información que falta.',
  initialized: 'Inicializado',
  installationControl: 'Installation Control',
  installationControlDescription:
    'Realizar las comprobaciones automáticas para detectar problemas comunes de instalación.',
  installationControlExecutedBy: 'Ejecutado por',
  installationControlHeadline: 'Estado de la última instalación',
  installationControlHistory: 'Historial de viajes',
  installationControlHistoryEmpty:
    'No se ha registrado ningún viaje de la instalación',
  installationControlInstructions: 'Mostrar instrucciones',
  installationControlSubheadline: 'Resumen de todos los viajes',
  installationDateErrorMessage:
    'No es posible ingresar una fecha de instalación de la puerta anterior a la fecha de instalación del ascensor',
  instantSubscription: 'Notificación instantánea',
  instantSubscriptionExplanation: (
    <>
      <b>Notificación instantánea</b> es una notificación inmediata por correo
      electrónico en cuanto se produce un error fatal en un ascensor. Los
      errores fatales son errores que pueden provocar el fallo inmediato del
      ascensor.
    </>
  ),
  invitationExpired: 'El enlace de la invitación ya no es válido.',
  inviteAsManager: 'El usuario puede invitar a otros usuarios.',
  inviteCoworkers: 'Invitar a compañeros de trabajo',
  ipaddress: 'Dirección IP',
  joinText: (name: string) => (
    <>
      Regístrate para unirte a de la organización <i>{name}</i>.
    </>
  ),
  landingDoor: 'Puerta de piso - Piso',
  landing_lock_roller_adjustment: 'Ajuste de la rueda de la cerradura de piso',
  landingCarDoorSpareParts: 'Piezas de repuesto para puertas de cabina/rellano',
  lastError: 'Último Error',
  lastExchangeDate: 'Última fecha de intercambio',
  lastMaintenanceDate: 'Última fecha de mantenimiento',
  lastMaintenanceScope: 'Alcance del último mantenimiento',
  lastMaintenanceScopeHint:
    'Seleccione el alcance del mantenimiento recomendado por Wittur que se realizó realmente en la última fecha de mantenimiento',
  lastName: 'Apellido',
  lastTimeDoorElectronicsChange:
    'Fecha de sustitución de la electrónica de puerta',
  latestSoftwarePackVersion: (version: string) =>
    `Versión del paquete de software: ${version}`,
  ld_counter_rollers_installation: 'Ruedas inferiores',
  ld_counter_rollers_installation_actions:
    '"""Para cada puerta de piso, comprueba que las ruedas se pueden girar fácilmente con la mano y que no estén a demasiada distancia de la guía. Si así fuera, afloja las ruedas y vuelve a ajustarlos para que estén lo más cerca posible de la guía, pero sin tocarla, y puedan girarse fácilmente con la mano. Si necesitas desmontar el acoplador para acceder a las ruedas, sigue las instrucciones de montaje."""',
  ld_counter_rollers_installation_description:
    'Las ruedas inferiores de la puerta de piso no están instaladas correctamente.',
  ld_roller: 'Rueda de puerta de piso',
  left: 'Izquierda',
  licenseRequired:
    'No tienes una licencia válida para este producto. Ponte en contacto con tu comercial Wittur para comprar este producto ElevatorSense.',
  lift: 'Ascensor',
  liftMovementDistance: 'Movimiento del ascensor',
  liftSetup: 'Configuración del ascensor',
  liftSetupComplete: 'Configuración completada.',
  liftSetupError: 'Faltan los datos correspondientes o están incompletos.',
  liftSetupError40:
    'El movimiento del ascensor no coincide con la marcha configurada.',
  liftSetupIncomplete: 'Configuración incompleta.',
  liftSetupPending:
    'Realice la configuración inicial de ElevatorSense en su ascensor. Sin la ejecución de la configuración no podrá utilizar algunas funciones de los productos ElevatorSense o los resultados de los productos ElevatorSense pueden diferir. Haga clic aquí para realizar la configuración.',
  liftSetupRepeat:
    'La marcha de configuración no se ha realizado con éxito. Vuelve a realizar la marcha teniendo en cuenta la descripción del proceso.',
  lifts: 'Ascensores',
  linkBox: 'Enlazar caja',
  linkToWebshop: (link: string) => (
    <>
      Si tiene una caja ElevatorSense y desea activar una licencia, haga clic{' '}
      <a href={link} className="clickable-link">
        aquí
      </a>
      .
    </>
  ),
  loading: 'Cargando',
  login: 'Acceder',
  loginText: 'Accede a tu cuenta.',
  logout: 'Salir',
  mandatory: 'Realizar Comprobación de Sustitución',
  manual: 'Manual',
  maxElectronics: 'Máx. ascensores',
  maxUsage: 'Límite de uso',
  members: 'Miembros',
  misalignment_cd_vs_ld: 'Alineación de puerta/patin',
  misalignment_cd_vs_ld_actions:
    'Regula las ruedas de la cerradura de la puerta de piso. El juego entre el patín y las ruedas debe ser el mismo en el lado izquierdo y en el derecho.',
  misalignment_cd_vs_ld_description:
    'El patín de la puerta de la cabina no está alineado con las ruedas de la cerradura de la puerta de piso.',
  misalignment_cd_vs_ld_sill_gap: 'Distnacia entre pisaderas',
  misalignment_cd_vs_ld_sill_gap_actions:
    'La distancia entre pisaderas en el extremo izquierdo y en el extremo derecho del hueco de la puerta no es igual. Si esto ocurre en todos los pisos, ajusta la posición de la pisadera de la puerta de la cabina del ascensor.',
  misalignment_cd_vs_ld_sill_gap_description:
    'La puerta de piso no está paralela a la puerta de la cabina.',
  mode: 'Modo',
  monitoring: 'Vigilancia',
  motor: 'Motor',
  motorTemperature: 'Temperatura del motor',
  myBuildingsSubscription: 'Solo de mis favoritos',
  name: 'Nombre',
  networkQuality: 'Calidad de la red',
  newInstallationControl: 'Nuevo control de instalación',
  next: 'Sig.',
  nextMaintenanceDate: 'Próxima fecha de mantenimiento',
  no: 'No',
  noBoxLinked:
    'El ascensor aún no está vinculado a una caja ELEVATORSENSE. Para utilizar todas las funciones de la aplicación, escanea el código QR de la caja o introduce manualmente el identificador de la caja.',
  noBuildingsVisible:
    'No hay edificios visibles en la vista actual del mapa. Intenta ajustar el mapa para ver más edificios.',
  noComponentsFound: 'No se encontraron componentes para este piso',
  noFavorite: 'No es favorito',
  noFavoritesChoosen:
    'Aún no has seleccionado ningún edificio favorito. Navega hasta tu edificio y haz clic en el icono de la estrella para añadirlo a tus edificios favoritos.',
  noInstallationControlFound:
    'El ESN de la electrónica de la puerta no coincide con el ESN de la última ejecución de la Installation Control. Compruebe si está conectado al ascensor correcto.',
  noSubscription: 'Ninguno',
  note: 'Aviso',
  noQRCodeDetected:
    'Aún no se detecta el código QR. Por favor, mueve la cámara para que el código QR esté enfocado y completamente visible en la vista.',
  nudgingSpeedDescription:
    'Velocidad de la puerta en condición de nudging. La curva de cierre/apertura se calcula para alcanzar esta velocidad ajustada si el nudging está activo.',
  nudgingSpeedNote:
    'La velocidad real de la puerta también está relacionada con los interruptores DIP de velocidad de los accionamientos. El ajuste a velocidad 4 significa el 100% del valor 3.',
  nudgingSpeedTitle: 'Velocidad de empuje (Nudging)',
  obsolete: 'Obsoleto',
  offline: 'Desconectado',
  offlineConnection:
    'Actualmente estás desconectado, pero puedes utilizar Wireless Programming.',
  ok: 'Mantenimiento reglamentario',
  online: 'En linea',
  openHoldForceDescription: 'Fuerza que se aplica en el extremo abierto.',
  openHoldForceNote:
    'Esta fuerza debe ser de entre 50N y 60N. Ten en cuenta que una fuerza demasiado baja provocará cierres y aperturas no deseados. Una fuerza de apertura final demasiado alta calentará el motor innecesariamente y puede que funcione en modo de reducción.',
  openHoldForceTitle: 'Fuerza de apertura final (retención)',
  openSpeedDescription:
    'Velocidad máxima de la puerta en sentido de apertura. La curva abierta se calcula para alcanzar esta velocidad ajustada, si es posible.',
  openSpeedNote:
    'La velocidad real de la puerta también está relacionada con los interruptores DIP de velocidad de los accionamientos. El ajuste a velocidad 4 significa el 100% del valor 1.',
  openSpeedTitle: 'Velocidad de apertura',
  otherReason: 'Otra razon',
  overdue: 'Atrasado',
  parameter: 'Parámetro',
  partInformation: 'Información del Componente',
  partIsStillOk: 'La parte todavía está bien',
  partNumber: 'Número de Parte',
  partName: 'Nombre de Parte',
  password: 'Contraseña',
  passwordResetSent:
    'Te hemos enviado un email con instrucciones para restablecer la contraseña. Comprueba tu bandeja de entrada.',
  pendingInvites: 'Invitaciones pendientes',
  planningAndAlerts: 'Planificación y alertas',
  position: 'Posición',
  positionOutDescription:
    'Introduciendo este parámetro se puede ajustar el relé de posición (relacionado con el FPC) para que conmute en cualquier posición de la puerta. El ajuste estándar es 0 (desactivado).',
  positionOutNote:
    'En caso de ajustar este parámetro a otro valor, el relé conmuta al alcanzar la posición. Esta función se utiliza prácticamente solo en los huecos con cabinas de premovimiento.',
  positionOutTitle: 'Posición de salida',
  postpone: 'Aplazar',
  powerReductionFactor: 'Factor de reducción de potencia',
  prev: 'Ant.',
  preventiveMaintenance: 'Mantenimiento preventivo',
  preventiveMaintenanceDescription:
    'Reemplace los repuestos de la puerta de su ascensor antes de que se rompan.',
  preventiveMaintenanceUnavailable:
    '¡Los datos introducidos están completos! ElevatorSense calculará ahora el programa de mantenimiento en función del uso de su ascensor. Este proceso puede tardar unos minutos.',
  priority: 'Prioridad',
  product: 'Producto',
  programmingDisabledHint:
    'La programación de la electrónica está actualmente desactivada por el hardware. Asegúrate de que el interruptor DIP 1 esté activado para permitir la programación del dispositivo.',
  programmingErrorConnection: (
    <>
      <p>
        We are discovering troubles with the connection to ELEVATORSENSE box.
      </p>
      <p>
        Please make sure to switch off your "mobile data connection" and ensure
        you are connected to the WIFI of the ELEVATORSENSE box only.
      </p>
      <p>
        Afterwards hit the <b>continue</b> button below.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    'No se pudo abrir la ventana. Asegúrate de que tu navegador no bloquea las ventanas emergentes.',
  programmingErrorRequest:
    'Solicitud fallida. Asegúrate de que tu dispositivo está conectado a la red WIFI de la caja ELEVATORSENSE.',
  programmingTool: 'Wireless Programming',
  programmingToolDescription:
    'Fácil configuración de la electrónica de la puerta.',
  pulley_is_touching_belt: 'Polea',
  pulley_is_touching_belt_actions:
    'Ajusta la alineación de la correa para que no toque los bordes de la brida de la polea.',
  pulley_is_touching_belt_description:
    'La brida de la polea está tocando la correa.',
  readyToRunTest: 'Listo para realizar la prueba.',
  realtimeMonitoringDescription:
    'Muestra los datos de los sensores y los gráficos de movimiento de la puerta en tiempo real.',
  realtimeMonitoringTitle: 'Real Time Diagnostics',
  recommended: 'Planificar revisión de sustitución',
  recommendedActions: 'Acciones recomendadas',
  recommendedDateOfExchange: 'Fecha recomendada de sustitución Comprobación',
  reload: 'Recargar',
  remove: 'Eliminar',
  reopenTimeDescription:
    'El parámetro Reapertura define el tiempo de espera tras el movimiento de reapertura automática en posición final abierta, antes de volver a cerrar la puerta.',
  reopenTimeNote:
    'Esta función se utiliza en caso de procedimiento de reapertura activado por la electrónica de la puerta y no por el controlador principal del ascensor.',
  reopenTimeParameter: 'Parámetro tiempo de reapertura',
  reopenTimeTitle: 'Tiempo de reapertura',
  repeatFloor:
    '¡Repite el proceso de control de la instalación para este piso!',
  repeatWholeRun: (
    <>
      ¡Por favor, repite todo el <b>proceso de</b> control de la instalación!
    </>
  ),
  replaced: 'Reemplazado',
  replacementWasPostponed: 'La sustitución se pospuso el:',
  requestNewLink: 'Solicitar un nuevo enlace',
  resetPassword: 'Restablecer la contraseña',
  resetPasswordText: 'Introduce una nueva contraseña.',
  revoke: 'Revocar',
  right: 'Derecha',
  rollerBumper: 'Rodillo Parachoques',
  rollerSynchronisationRope: 'Cable de sincronización',
  runs: 'Carreras',
  save: 'Guardar',
  scan: 'Escanear',
  scanned: 'Escaneado',
  scanAgain: 'Escanear de nuevo',
  searchPlaces: 'Buscar lugares',
  select: 'Seleccionar',
  selectCameraMode: 'Seleccionar modo de cámara',
  selectDoorModel: 'Seleccionar modelo de puerta',
  selectDoorType: 'Seleccionar tipo de puerta',
  selectModifiedFloors:
    'Selecciona los pisos en los que se han realizado cambios desde la última marcha de prueba. Se han preseleccionado los pisos en los que se produjeron errores durante la última marcha.',
  selectPartsHint:
    'Compruebe qué parte está instalada y haga clic en la imagen correspondiente.',
  send: 'Enviar',
  sendResults: 'Enviar resultados',
  sendTo: '"Enviar resultados a las siguientes direcciones de correo;"',
  sensorData: 'Datos del sensor',
  serviceDriveSwitch: 'Service drive',
  settings: 'Ajustes',
  shareBody: (link: string) => `Ver resultado prueba:\n${link}`,
  shareResults: 'Compartir resultados',
  shareSubject: 'Resultado ELEVATORSENSE',
  shoe: 'Deslizadera',
  showPassedTests: 'Mostrar las pruebas superadas...',
  showTerms: 'Ver las condiciones de uso',
  signUp: 'Registrarse',
  skid: 'Patín',
  slideingshoe_failure: 'Error deslizadera',
  slideingshoes_usage: 'Desgaste ',
  somethingWentWrong: 'Algo ha ido mal. Vuelva a intentarlo.',
  sortBy: 'Ordenar por',
  sparePartDoesNotFit: 'La pieza de repuesto no encaja',
  sparePartNotAvailable: 'Recambio no disponible',
  speed: 'Velocidad',
  start: 'Iniciar',
  startNewTestRun: 'Nueva marcha de prueba',
  started: 'Ejecutándose',
  starting_floor: 'Piso inicial',
  status: 'Estado',
  subscriptionHint:
    'Solo recibirás notificaciones por correo electrónico si has comprado los productos adecuados para tu ElevatorSense Box.',
  subscriptionText:
    'Elige las notificaciones de correo electrónico que deseas recibir.',
  subscriptions: 'Notificaciones',
  summarySubscription: 'Informe diario',
  summarySubscriptionExplanation: (
    <>
      El <b>informe diario</b> incluye un correo electrónico con un resumen de
      todos los edificios y ascensores seleccionados en el que se indica el
      número de errores.
    </>
  ),
  temperature: 'Temperatura',
  testCompleted: 'Prueba finalizada.',
  testResults: 'Resultados de la prueba',
  time: 'Hora',
  tooHigh: 'Demasiado alto',
  tooLoose: 'Demasiado suelto',
  tooLow: 'Demasiado bajo',
  tooTight: 'Demasiado tenso',
  top: 'arriba',
  topToBottom: 'De arriba hacia abajo',
  touching: 'en contacto',
  transformer: 'Transformador',
  truckRoller: 'Ruedas de carro',
  truckRollerInferior: 'Ruedas de carro inferior',
  truckRollerSuperior: 'Ruedas de carro superior',
  twisted_cd_panels: 'Hojas de puerta de cabina no alineadas',
  twisted_ld_panels: 'Hojas no alineadas',
  unlinkBox: 'Desenlazar caja',
  unreadNotifications: (n: number) =>
    `${n} notificaciones sin leer${n === 1 ? '' : 's'}`,
  update: 'Actualizar',
  updateRequired: 'Actualización necesaria',
  updateStaticData: 'Actualizar datos',
  updating: 'Actualizando...',
  upToDate: 'Hasta la fecha',
  usage: 'Uso',
  usedElectronics: 'Ascensores utilizados',
  validFrom: 'Válido desde',
  validUntil: 'Válido hasta',
  vibration: 'Vibración',
  viewErrorLog: 'Ver Registro de errores',
  waitWhileInitializingTest: 'Espere mientras se inicia la prueba...',
  waitingForData: 'Esperando a que lleguen los nuevos datos',
  waitingForTestResults: 'Esperando los resultados de la prueba...',
  warning: 'Advertencia',
  warnings: 'Advertencias',
  warrantyExtensionRequestedOn: (date: string) =>
    `La extensión de garantía fue solicitada en ${date}`,
  whyNotReplaced: `'Why was the component not replaced?'`,
  wifiScanned: 'WIFI ha sido escaneado',
  years: 'años',
  yes: 'Sí',
  zero_position: 'Posición cero',
  zero_position_description: 'La posición cero no es correcta.',
  zero_position_eco_actions:
    '"""Procedimiento ECO+: 1) Pon S1DIP1 en ON (modo Servicio). 2) Cierra la puerta manualmente (posición cero). 3) Pulsa el botón Aprendizaje > 10s (Longlearn). 4) Abre y cierra la puerta utilizando el botón de accionamiento de servicio. 5) Mantén pulsados los botones del extremo de apertura de la puerta y del extremo de cierre de la puerta durante > 2s. Resultado: El LED de estado debe apagarse después de 2 ciclos."""',
  zero_position_midi_actions:
    '" ""Procedimiento MIDI/SUPRAV2: Cierra la puerta manualmente (posición cero). Sigue las instrucciones del pictograma."""',
};
