import React from 'react';
import styled from 'styled-components';

import { Button } from '../components/common/Button';
import { DeleteIcon } from '../components/common/Icons';
import { Page } from '../components/common/Page';
import { Row } from '../components/common/Row';
import { Stack } from '../components/common/Stack';
import { CardTitle } from '../components/common/typography';
import { Select } from '../components/form/Select';
import { InviteUsers } from '../components/InviteUsers';
import { useApiMutation, useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';
import { space } from '../tokens';
import { useUser } from '../UserProvider';

export function CompanyMembers() {
  const t = useTranslation();
  const invites = useApiQuery('getInvites');
  const members = useApiQuery('getMembers');
  const currentUser = useUser();

  const cancelInvite = useApiMutation('cancelInvite', {
    onSuccess: invites.invalidate,
  });
  const deleteMember = useApiMutation('deleteMember', {
    onSuccess: members.invalidate,
  });
  const updateMember = useApiMutation('updateMember', {
    onSuccess: members.invalidate,
  });

  const UserDetails = styled.div`
    margin-right: auto;
    padding-bottom: ${space[4]};
  `;

  const ButtonContainer = styled.div`
    display: flex;
    gap: ${space[4]};
    padding-bottom: ${space[4]};
    @media screen and (max-width: 550px) {
      width: 100%;
      justify-content: center;
      margin-left: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  `;

  return (
    <Page pageTitle={t.members} backLink="/">
      <Stack gap={space[7]}>
        {invites.isSuccess && invites.data.length > 0 && (
          <Stack>
            <CardTitle>{t.pendingInvites}</CardTitle>
            {invites.data?.map((invite) => (
              <Row key={invite.id}>
                <div style={{ marginRight: 'auto' }}>{invite.email}</div>
                <Button size="small" onClick={() => cancelInvite(invite.id)}>
                  {t.revoke}
                </Button>
              </Row>
            ))}
          </Stack>
        )}
        <InviteUsers onSuccess={invites.invalidate} />
        <Stack>
          <CardTitle>{t.members}</CardTitle>
          {members.data?.map((user) => {
            if (currentUser?.id === user.id) return;
            return (
              <Row key={user.id}>
                <UserDetails>
                  <p>{`${user.firstName} ${user.lastName}`}</p>
                  <p>{user.email}</p>
                </UserDetails>
                <ButtonContainer>
                  <Select
                    value={user.role!}
                    onChange={(ev) =>
                      updateMember(user.id, { role: ev.target.value as any })
                    }
                  >
                    <option value="user">User</option>
                    <option value="manager">Manager</option>
                  </Select>
                  <Button
                    variant="secondary"
                    size="small"
                    minWidth="small"
                    onClick={() => deleteMember(user.id)}
                  >
                    <DeleteIcon />
                  </Button>
                </ButtonContainer>
              </Row>
            );
          })}
          <Row key={currentUser?.id}>
            <UserDetails>
              <p>{`${currentUser?.firstName} ${currentUser?.lastName}`}</p>
              <p>{currentUser?.email}</p>
            </UserDetails>
          </Row>
        </Stack>
      </Stack>
    </Page>
  );
}
