import React from 'react';

export const en = {
  aborted: 'Aborted',
  accelerationDescription:
    'Acceleration factor which is used for curve calculation.',
  accelerationNote:
    'This factor has to be high enought to reach the adjusted speeds, otherwise the door will not reach the wanted speed.',
  accelerationTitle: 'Acceleration',
  acceptTerms: 'I accept the terms of usage',
  add: 'Add',
  addBuilding: 'Add Building',
  addLift: 'Add Elevator',
  addressHint:
    'Enter a company or building name to get address and image suggestions.',
  adjustCameraSettings:
    'You can also adjust the camera settings using the dropdown menu.',
  admin: 'Admin',
  all: 'All',
  allBuildingsSubscription: 'From all buildings',
  alphabetical: 'Alphabetical',
  ambientTemperature: 'Box Temperature',
  assetNumber: 'Elevator ID',
  assetNumberHint:
    'Please enter the ID (aka facility/equipment number) of the elevator if you have it to hand.',
  auto: 'Auto',
  back: 'Back',
  backToDashboard: 'Back to Dashboard',
  belt_tension: 'Belt Tension',
  belt_tension_actions:
    'Adjust the belt tension as described in the installation manual.',
  beltclamp: 'Beltclamp',
  belt_tension_description: 'The Belt tension is too high/low.',
  bottom: 'bottom',
  bottomToTop: 'Bottom to Top',
  boxLinkedToOtherLift: (lift: string, building: string) =>
    `The box is linked to ${lift} in ${building}. Continue?`,
  boxNotReady: 'The selected box is not ready.',
  boxOfflineHint:
    'If you want to update one of the boxes listed below, please connect it to the internet first.',
  boxScannedNoIdFound: 'Box scanned, but no ID found.',
  boxSoftware: 'Software',
  boxSoftwareStatusError:
    'The status of the boxes could not be retrieved: please check the Internet connection of the box and try again. If the problem persists, please contact your Wittur representative.',
  boxSoftwareTitle: 'Box Software',
  boxSoftwareUpdateError:
    'Failed to update box software: please check the internet connection of the box and try again. If the problem persists, contact your Wittur representative.',
  box_id: 'Box ID',
  brakeParDescription:
    'Because of the adjustment of this parameter the delay and creep to the open / close end is influenced (pre‐adjusted at ‘5’).',
  brakeParNote:
    'The possible adjustment is ‘0’..’9’, but the earliest brake is reached with parameter ‘0’ and the latest delay is reached with parameter ‘9’.',
  brakeParTitle: 'Break Parameter',
  buffer: 'Buffer',
  building: 'Building',
  buildingName: 'Name',
  buildings: 'Buildings',
  buildingsWithoutValidAddress: 'Buildings without valid address',
  bushingClose: 'Bushing Close',
  bushingOpen: 'Bushing Open',
  cablechain: 'Cablechain',
  cableTensorSpring: 'Cable tensor spring',
  callFloorsInstruction: (
    <>
      Open the door and <b>call all other floors</b>. Step outside the car and
      click "Continue".
    </>
  ),
  cameraMode: 'Camera mode',
  cancel: 'Cancel',
  car: 'Car',
  carDoor: 'Car Door',
  carDoorCounter: 'Door Cycle Counter of previous Door Electronics',
  carDoorModified: 'Car door has been modified',
  cd_counter_rollers_installation: 'Counter Rollers',
  cd_counter_rollers_installation_actions:
    'For each landing door, check that the rollers can be turned easily by hand and that their distance from the rail is not too big. If this happens,  loosen the rollers and re-adjust them so that they are as close as possible to the rail, but do not touch it, and can still be turned easily by hand.\n\n  If you need to dismount the coupler to access the rollers, follow the installation instructions.',
  cd_counter_rollers_installation_description:
    'CD counter rollers are not installed correctly.',
  cd_roller: 'CD Roller',
  chooseFloorOrCardoor: 'Choose Floor / Cardoor',
  choosePartNumber: 'Choose Part Number',
  chooseReason: 'Choose a reason (optional)',
  chooseStartingFloorInstructions:
    'Please select the starting floor of the control run. The starting floor can either be the top or the bottom floor.',
  clearDriveErrors: 'Clear',
  close: 'Close',
  closeDoorInstructions: (floor: string) => (
    <>
      Head to to the <b>{floor}</b> floor. Make sure the car door is closed
      before proceeding.
    </>
  ),
  closeForcePotiDescription:
    'Set force limit used in opening and closing of the door.',
  closeForcePotiNote:
    'Adjustable via potentiometer on the electronic. On newer electronics like MIDIV2 set able here in Service drive Mode if needed (no need to adjust in standard applications due to self-learning).',
  closeForcePotiTitle: 'Close force limit',
  closeHoldForceDescription: 'Force which is applied at close end.',
  closeHoldForceNote:
    'This force should be between 50N and 70N. Be aware too low force will cause not wanted coupler openings. Too high force will heat up the motor uneccessary and may it will operate in reduction mode.',
  closeHoldForceTitle: 'Close End (Hold) Force',
  closeSpeedDescription:
    'Maximal door speed in closing direction. The close curve is calculated to reach this adjusted speed if possible.',
  closeSpeedNote:
    'The real door speed is also related to the drives Speed DIP switches. Adjustment to speed 4 means 100% of Value 2.',
  closeSpeedTitle: 'Close Speed',
  closing_device_failure: 'Closing Device',
  closing_device_failure_actions:
    'Check that the closing device for the landing door  works correctly in all its components (spirator, closing spring and closing weight).\n    The landing door must be able to close by itself from every door position.',
  closing_device_failure_description:
    'The Closing device is not installed correctly. The force required to close the door is too high.',
  completed: 'Completed',
  componentDetails: 'Component Details',
  componentDetailsHint:
    'For some components, there are multiple part number options that must be selected. This is important information for using the preventive maintenance function. Please click on the listed items to select the actual part installed.',
  confirm: 'Confirm',
  confirmDeleteBuilding: 'Do you really want to delete this building?',
  confirmDeleteErrorHistory: 'Do you really want to delete this error history?',
  confirmDeleteFavoriteBuilding:
    'Do you really want to remove this building from your favorites?',
  confirmDeleteLift: 'Do you really want to delete this elevator?',
  continue: 'Continue',
  couplerelease: 'Couplerrelease',
  couplerFriction: 'Coupler Friction',
  couplerMovementParameter: 'Coupler Movement Parameter',
  couplerSpeedDescription:
    'This value defines the maximum speed of the belt, in the defined coupler area.',
  couplerSpeedTitle: 'Coupler Speed',
  couplerWitdhDescription:
    'This value defines the belt distance in the coupler area. This value is resulting after the learn procedure.',
  couplerWitdhTitle: 'Coupler Width',
  currentVersion: 'Current Version',
  couplerwidth: 'Coupler Width',
  date: 'Date',
  delete: 'Delete',
  deleteErrorHistory: 'Delete error history',
  directConnection: (
    <>
      <p>
        Connect to the WIFI of the ELEVATORSENSE box by scanning the QR code
        printed on the box with your phone's camera app.
      </p>
      <p>
        Once connected go back to this screen an hit the <b>continue</b> button
        below.
      </p>
    </>
  ),
  disconnectProgrammingTool: 'Disconnect Wireless Programming',
  divpulley: 'Divpulley',
  documentation: 'Documentation',
  doorContact: 'Door contact',
  doorCycleCounter: 'Door Cycle Counter',
  doorCycleError1: 'No door cycle data was received.',
  doorCycleError2: 'The door cycle movement was disrupted.',
  doorCycleError4: 'A re-opening of the door was detected.',
  doorCycleError5: 'A re-closing of the door was detected.',
  doorCycleError7: 'No valid door cycles were received.',
  doorCycles: 'Door cycles',
  doorFriction: 'Door Friction',
  doorInstallationDate: 'Door Installation Date',
  doorIsClosed: 'Yes, the door is closed',
  doorModel: 'Door Model',
  doorModelNotSupported:
    'The selected door model is not available for preventive maintenance.',
  doorMovementParameter: 'Door Movement Parameter',
  doorPosition: 'Door Position',
  doorSerialNumber: 'Door Serial Number',
  doorType: 'Door Type',
  doorwidth: 'Door Width',
  downloadCertificate: 'Download certificate',
  downloadLicense: 'Download all licenses as CSV',
  downloadRequestedExtendedWarranty:
    'Download all requests for warranty extension',
  drive_motor_noise: 'Drive Motor Noise',
  driveesn: 'ESN',
  drivesoftware: 'Firmware',
  editBuilding: 'Edit Building',
  editLift: 'Edit Elevator',
  elecBoard: 'Elec board',
  elevatorIdNotFound: (
    <>
      To request an extended warranty, you must enter the door serial number in
      the elevator settings. <b>Click here to go to the settings.</b>
    </>
  ),
  elevatorInstallationDate: 'Elevator Installation Date',
  elevatorUsageType: 'Elevator Usage Type',
  email: 'Email',
  encoderTemperature: 'Encoder Temperature',
  error: 'Error',
  errorDescription: 'Error Description',
  errorEarlierThanInstallationDate:
    'It is not possible to enter a date earlier than the installation date.',
  errorInvalid: 'Please enter a valid value.',
  errorInvalidOperation: 'Invalid operation',
  errorMessages: 'Error Messages',
  errorMessagesDescription: 'Display of logged error messages.',
  errorNotFound: 'Resource not found',
  errorRetryCamera:
    'Unable to access the camera. Retrying, the page will reload...',
  errorRequired: 'This field is required.',
  errorUnableToAccessCamera:
    'Unable to access the camera after multiple attempts. Please refresh the page or check camera permissions.',
  errorUnknownOrForbiddenBox:
    'The Box is not available. Please contact your Wittur service if you need support.',
  errors: 'Errors',
  errorsEmpty: 'No messages.',
  errorsEmptyHint:
    'Please check your filters and maybe enable addtional types.',
  extendWarranty: 'Extend Warranty',
  fatalError: 'Fatal Error',
  fatalErrors: 'Fatal Errors',
  favorite: 'Favorite',
  favorites: 'Favorites',
  figureAdjustment: 'Adjustment',
  figureAugustaEVO: 'Example Augusta EVO',
  figureClosingWeight: 'Closing weight',
  figureEco: 'ECO+',
  figureHydra: 'Example Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'Not OK',
  figureOk: 'OK',
  figureSGV: 'Example SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Closing spring',
  firstName: 'First name',
  floor: 'Floor',
  floors: 'Floors',
  followingBoxScanned: 'Following box is scanned',
  forgotPassword: 'Forgot your password?',
  forgotPasswordText:
    'Enter your email address in order to reset your password.',
  groundFloor: 'Ground Floor',
  home: 'Home',
  incompleteElevatorSettings:
    'In order to use preventive maintenance, we need more information about this elevator. Please go to your elevator settings and fill in the missing information.',
  initialized: 'Initialized',
  installationControl: 'Installation Control',
  installationControlDescription:
    'Perform automatic checks to detect common installation issues.',
  installationControlExecutedBy: 'Executed by',
  installationControlHeadline: 'Latest Installation Status',
  installationControlHistory: 'Run History',
  installationControlHistoryEmpty: 'No recorded installation runs',
  installationControlInstructions: 'Show Instructions',
  installationControlSubheadline: 'Summary of all runs',
  installationDateErrorMessage:
    'It is not possible to enter an earlier door installation date than the elevator installation date',
  instantSubscription: 'Instant notification',
  instantSubscriptionExplanation: (
    <>
      <b>Instant notification</b> is an immediate notification by e-mail as soon
      as a fatal error has occurred on an elevator. Fatal errors are errors that
      can lead to the immediate failure of the elevator.
    </>
  ),
  invitationExpired: 'The invitation link is no longer valid.',
  inviteAsManager: 'User may invite other users.',
  inviteCoworkers: 'Invite coworkers',
  ipaddress: 'IP address',
  joinText: (name: string) => (
    <>
      Please sign up to join the <i>{name}</i> organization.
    </>
  ),
  landingDoor: 'Landing Door - Floor',
  landing_lock_roller_adjustment: 'Landing Lock Roller Adjustment',
  landingCarDoorSpareParts: 'Landing/Car Door Spare Parts',
  lastError: 'Last Error',
  lastExchangeDate: 'Last Date of Exchange',
  lastMaintenanceDate: 'Last Maintenance Date',
  lastMaintenanceScope: 'Last Maintenance Scope',
  lastMaintenanceScopeHint:
    'Select the maintenance scope recommended by Wittur that was actually performed on the last maintenance date.',
  lastName: 'Last name',
  lastTimeDoorElectronicsChange: 'Date of Door Electronics Replacement',
  latestSoftwarePackVersion: (version: string) =>
    `Software pack version: ${version}`,
  ld_counter_rollers_installation: 'Counter Rollers',
  ld_counter_rollers_installation_actions:
    'For each landing door, check that the rollers can be turned easily by hand and that their distance from the rail is not too big. If this happens,  loosen the rollers and re-adjust them so that they are as close as possible to the rail, but do not touch it, and can still be turned easily by hand.\n\n  If you need to dismount the coupler to access the rollers, follow the installation instructions.',
  ld_counter_rollers_installation_description:
    'LD counter rollers are not installed correctly.',
  ld_roller: 'LD Roller',
  left: 'Left',
  licenseRequired:
    "You haven't purchased a valid license for this product. Please contact your Wittur sales person to buy this ElevatorSense product.",
  lift: 'Elevator',
  liftMovementDistance: 'Lift movement',
  liftSetup: 'Lift Setup',
  liftSetupComplete: 'Setup completed.',
  liftSetupError: 'The corresponding data is incomplete or missing.',
  liftSetupError40: 'The elevator movement does not match the setup run.',
  liftSetupIncomplete: 'Setup incomplete.',
  liftSetupPending:
    'Please perform the setup run for initial setup of ElevatorSense on your elevator. Without the setup run you will not be able to use some functionalities of the ElevatorSense products or the results of the ElevatorSense products may differ. Click here to perform the setup run.',
  liftSetupRepeat:
    'The setup run was not successful. Please perform the run again, taking into account the process description.',
  lifts: 'Elevators',
  linkBox: 'Link Box',
  linkToWebshop: (link: string) => (
    <>
      If you have an ElevatorSense box and you would like to activate a license
      please click{' '}
      <a href={link} className="clickable-link">
        here
      </a>
      .
    </>
  ),
  loading: 'Loading',
  login: 'Login',
  loginText: 'Please log in with your account.',
  logout: 'Logout',
  mandatory: 'Perform Replacement Check',
  manual: 'Manual',
  maxElectronics: 'Max elevators',
  maxUsage: 'Max Usage',
  members: 'Members',
  misalignment_cd_vs_ld: 'Door/coupler Alignment',
  misalignment_cd_vs_ld_actions:
    'Adjust the landing door lock rollers. The running clearance between the coupler vanes and the rollers must be the same on the left and the right side.',
  misalignment_cd_vs_ld_description:
    'The Car Door coupler is misaligned with the Landing Door lock rollers.',
  misalignment_cd_vs_ld_sill_gap: 'Sill Gap',
  misalignment_cd_vs_ld_sill_gap_actions:
    'The sill gap on the left end and on the right end of the door opening is not equal. If this occurs on all landings, adjust the car door sill position.',
  misalignment_cd_vs_ld_sill_gap_description:
    'The landing door is not parallel to the car door.',
  mode: 'Mode',
  monitoring: 'Monitoring',
  motor: 'Motor',
  motorTemperature: 'Motor Temperature',
  myBuildingsSubscription: 'From my favorites only',
  name: 'Name',
  networkQuality: 'Network Quality',
  newInstallationControl: 'New Installation Control',
  next: 'Next',
  nextMaintenanceDate: 'Next maintenance date',
  no: 'No',
  noBoxLinked:
    'The elevator is not yet linked to an ELEVATORSENSE box. In order to use all features of the app, please scan the QR code on the box or enter the box-id manually.',
  noBuildingsVisible:
    'No buildings are visible in the current map view. Try adjusting the map to see more buildings.',
  noComponentsFound: 'No components found for this floor',
  noFavorite: 'Not a favorite',
  noFavoritesChoosen:
    'You have not selected any favorite buildings yet. Navigate to your building and click the star icon to add it to your favorite buildings.',
  noInstallationControlFound:
    'The ESN of the door electronics does not match the ESN of the last Installation Control run. Please check if you are connected to the correct elevator.',
  noSubscription: 'None',
  note: 'Note',
  noQRCodeDetected:
    'No QR code detected yet. Please move the camera so that the QR code is in focus and fully visible in the view.',
  nudgingSpeedDescription:
    'Door speed in Nudging condition. The close/open curve is calculated to reach this adjusted speed if nudging is active.',
  nudgingSpeedNote:
    'The real door speed is also related to the drives Speed DIP switches. Adjustment to speed 4 means 100% of Value 3.',
  nudgingSpeedTitle: 'Nudging Speed',
  obsolete: 'Obsolete',
  offline: 'Offline',
  offlineConnection:
    'You are currently offline, but you can use the Programming tool.',
  ok: 'Regular Maintenance',
  online: 'Online',
  openHoldForceDescription: 'Force which is applied at open end.',
  openHoldForceNote:
    'This force should be between 50N and 60N. Be aware too low force will cause not wanted closings and openings. Too high open end force will heat up the motor uneccessary and may it will operate in reduction mode.',
  openHoldForceTitle: 'Open End (Hold) Force',
  openSpeedDescription:
    'Maximal door speed in opening direction. The open curve is calculated to reach this adjusted speed if possible.',
  openSpeedNote:
    'The real door speed is also related to the drives Speed DIP switches. Adjustment to speed 4 means 100% of Value 1.',
  openSpeedTitle: 'Open Speed',
  otherReason: 'Other reason',
  overdue: 'Overdue',
  parameter: 'Parameter',
  partInformation: 'Part Information',
  partIsStillOk: 'Part is still OK',
  partNumber: 'Part Number',
  partName: 'Part Name',
  password: 'Password',
  passwordResetSent:
    "We've sent you an email with instructions on how to reset your password. Please check your inbox.",
  pendingInvites: 'Pending Invites',
  planningAndAlerts: 'Planning and alerts',
  position: 'Position',
  positionOutDescription:
    'By entering this parameter the position relay (FPC related) can be adjusted to switch at any door position. Standard adjustment is 0 (deactivated).',
  positionOutNote:
    'In case of setting this parameter to another value, the relay is switching when reaching the possition. This function is almost only used in shafts with premoving cabins.',
  positionOutTitle: 'Position Out',
  postpone: 'Postpone',
  powerReductionFactor: 'Power Reduction Factor',
  prev: 'Prev',
  preventiveMaintenance: 'Preventive Maintenance',
  preventiveMaintenanceDescription:
    'Replace your elevator door spare parts before they break.',
  preventiveMaintenanceUnavailable:
    'The entered data is complete! ElevatorSense will now calculate the maintenance schedule based on the usage of your elevator. This process may take a few minutes.',
  priority: 'Priority',
  product: 'Product',
  programmingDisabledHint:
    'Programming of the electronic is currently disabled by the hardware. Please make sure that the service drive mode is turned on to allow programming of the device.',
  programmingErrorConnection: (
    <>
      <p>
        We are discovering troubles with the connection to ELEVATORSENSE box.
      </p>
      <p>
        Please make sure to switch off your "mobile data connection" and ensure
        you are connected to the WIFI of the ELEVATORSENSE box only.
      </p>
      <p>
        Afterwards hit the <b>continue</b> button below.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    "Failed to open window. Make sure popups aren't blocked by your browser.",
  programmingErrorRequest:
    'Request failed. Please make sure your device is connected to the WIFI network of the ELEVATORSENSE box.',
  programmingTool: 'Wireless Programming',
  programmingToolDescription: 'Easy configuration of door electronics.',
  pulley_is_touching_belt: 'Pulley',
  pulley_is_touching_belt_actions:
    'Adjust belt alignment so that the belt does not touch the pulley flange borders.',
  pulley_is_touching_belt_description:
    'The pulley flange is touching the belt.',
  readyToRunTest: 'Ready to run the test.',
  realtimeMonitoringDescription:
    'View sensor data and door motion graphs in real time.',
  realtimeMonitoringTitle: 'Real Time Diagnostics',
  recommended: 'Plan Replacement Check',
  recommendedActions: 'Recommended Actions',
  recommendedDateOfExchange: 'Recommended Date of Replacement Check',
  reload: 'Reload',
  remove: 'Remove',
  reopenTimeDescription:
    'The Reopen parameter defines the waiting time periode after the automatic reopen movement in open end position, before closing the door again.',
  reopenTimeNote:
    'This function is used in case of reopening procedure triggered by the door electronic an not by the main lift controller.',
  reopenTimeParameter: 'Reopen time parameter',
  reopenTimeTitle: 'Reopen Time',
  repeatFloor: 'Please repeat the installation control process for this floor!',
  repeatWholeRun: (
    <>
      Please repeat the <b>full</b> installation control process!
    </>
  ),
  replaced: 'Replaced',
  replacementWasPostponed: 'The replacement was postponed on:',
  requestNewLink: 'Request new link',
  resetPassword: 'Reset password',
  resetPasswordText: 'Please enter a new password.',
  revoke: 'Revoke',
  right: 'Right',
  rollerBumper: 'Roller Bumper',
  rollerSynchronisationRope: 'Roller synchronisation rope',
  runs: 'Runs',
  save: 'Save',
  scan: 'Scan',
  scanned: 'Scanned',
  scanAgain: 'Scan again',
  searchPlaces: 'Search places',
  select: 'Select',
  selectCameraMode: 'Select camera mode',
  selectDoorModel: 'Select Door Model',
  selectDoorType: 'Select Door Type',
  selectModifiedFloors:
    'Please select the floors on which changes have been made since the last test run. The floors on which errors occurred during the last run are preselected.',
  selectPartsHint:
    'Please check which part is installed and click on the corresponding image.',
  send: 'Send',
  sendResults: 'Send Results',
  sendTo: 'Send results to the following email addresses:',
  sensorData: 'Sensor Data',
  serviceDriveSwitch: 'Service drive switch',
  settings: 'Settings',
  shareBody: (link: string) => `View test result:\n${link}`,
  shareResults: 'Share Results',
  shareSubject: 'ELEVATORSENSE Result',
  shoe: 'Shoe',
  showPassedTests: 'Show passed tests ...',
  showTerms: 'Show terms of usage',
  signUp: 'Sign up',
  skid: 'Skid',
  slideingshoe_failure: 'Slideingshoe Failure',
  slideingshoes_usage: 'Slideingshoes Usage',
  somethingWentWrong: 'Something went wrong. Please try again.',
  sortBy: 'Sort By',
  sparePartDoesNotFit: 'Spare part does not fit',
  sparePartNotAvailable: 'Spare part not available',
  speed: 'Speed',
  start: 'Start',
  startNewTestRun: 'New Test Run',
  started: 'Running',
  starting_floor: 'Starting Floor',
  status: 'Status',
  subscriptionHint:
    'You will receive email notifications only if you have purchased appropriate products for your ElevatorSense Box.',
  subscriptionText:
    'Please choose the email notifications you would like to receive.',
  subscriptions: 'Notifications',
  summarySubscription: 'Daily report',
  summarySubscriptionExplanation: (
    <>
      The <b>daily report</b> includes an email with an overview of all selected
      buildings and elevators with an indication of the number of errors.
    </>
  ),
  temperature: 'Temperature',
  testCompleted: 'Test completed.',
  testResults: 'Test Results',
  time: 'Time',
  tooHigh: 'Too high',
  tooLoose: 'Too loose',
  tooLow: 'Too low',
  tooTight: 'Too tight',
  top: 'top',
  topToBottom: 'Top to Bottom',
  touching: 'touching',
  transformer: 'Transformer',
  truckRoller: 'Truck roller',
  truckRollerInferior: 'Truck roller inferior',
  truckRollerSuperior: 'Truck roller superior',
  twisted_cd_panels: 'Twisted CD Panels',
  twisted_ld_panels: 'Twisted Panels',
  unlinkBox: 'Unlink Box',
  unreadNotifications: (n: number) =>
    `${n} unread notification${n === 1 ? '' : 's'}`,
  update: 'Update',
  updateRequired: 'Update Required',
  updateStaticData: 'Update Data',
  updating: 'Updating...',
  upToDate: 'Up-to-date',
  usage: 'Usage',
  usedElectronics: 'Elevators used',
  validFrom: 'Valid from',
  validUntil: 'Valid until',
  vibration: 'Vibration',
  viewErrorLog: 'View Error Log',
  waitWhileInitializingTest: 'Please wait while initializing the test ...',
  waitingForData: 'Waiting for new data to arrive',
  waitingForTestResults: 'Waiting for test results ...',
  warning: 'Warning',
  warnings: 'Warnings',
  warrantyExtensionRequestedOn: (date: string) =>
    `Warranty extension was request on ${date}`,
  whyNotReplaced: 'Why was the component not replaced?',
  wifiScanned: 'WIFI has been scanned',
  years: 'years',
  yes: 'Yes',
  zero_position: 'Zero Position',
  zero_position_description: 'The zero position is not correct.',
  zero_position_eco_actions:
    'ECO+ procedure:\n  1) Set S1DIP1 to ON (Service mode).\n  2) Close the door by hand (zero position).\n  3) Press the learn button > 10s (Longlearn).\n  4) Open and close the door using the service drive button.\n  5) Hold down the buttons at the door open end and at the door close end for > 2s.\n  Result: The state LED must go out after 2 cycles.\n  ',
  zero_position_midi_actions:
    'MIDI/SUPRAV2 procedure:\n  Close the door by hand (zero position).\n  Follow the instructions in the pictogram.',
};

export type Translations = typeof en;
export type TranslationKey = keyof Translations;

export type TranslatableErrorKey =
  | 'programmingErrorNoPopup'
  | 'programmingErrorConnection'
  | 'programmingErrorRequest';

type StringKey<T extends TranslationKey> = Translations[T] extends string
  ? T
  : never;

export type StringTranslations = {
  [K in keyof Translations as StringKey<K>]: Translations[K];
};
export type StringTranslationKey = keyof StringTranslations;
