import React from 'react';
import styled from 'styled-components';

import {
  Content,
  IconProps,
  StyledCard,
  Title,
} from '../../components/common/Card';
import { Stack } from '../../components/common/Stack';
import { CardSubTitle, Overline } from '../../components/common/typography';
import { borderRadius, color, space } from '../../tokens';

const Icon = styled.div<{
  selectedColor?: string;
}>`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.selectedColor === 'warning'
      ? color.warning
      : props.selectedColor === 'error'
      ? color.error
      : color.success};
  color: ${color.textOnPrimary};
  padding: ${space['2']};
  border-radius: 0 ${borderRadius.default} ${borderRadius.default} 0;
`;

interface ComponentProps extends IconProps {
  priority?: string;
}

export function ComponentCard({
  icon,
  title,
  subTitle,
  to,
  disabled,
  status,
  children,
  priority,
  ...rest
}: ComponentProps) {
  const iconColor =
    priority === 'mandatory'
      ? 'error'
      : priority === 'recommended'
      ? 'warning'
      : 'success';

  return (
    <StyledCard to={to} disabled={disabled} status={status} {...rest}>
      <Content>
        <Stack gap={space[2]}>
          <Title>
            {title}:{subTitle && <CardSubTitle>{subTitle}</CardSubTitle>}
          </Title>
          <Overline>{children}</Overline>
        </Stack>
      </Content>
      {icon && <Icon selectedColor={iconColor}>{icon}</Icon>}
    </StyledCard>
  );
}
