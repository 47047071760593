import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

import { color } from '../../tokens';
import { IconButton } from './IconButton';
import { CloseIcon } from './Icons';

const Backdrop = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  background: ${color.primary};
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Close = styled(IconButton)`
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
`;

const Circle = styled.div`
  color: ${color.primary};
  background: ${color.background};
  border-radius: 50%;
`;

type Props = {
  onClose: () => void;
  children: ReactNode;
};
export function Overlay({ onClose, children }: Props) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  return (
    <div>
      <Backdrop>
        <Close
          onClick={(ev) => {
            ev.stopPropagation();
            onClose();
          }}
        >
          <Circle>
            <CloseIcon size="32px" />
          </Circle>
        </Close>
        {children}
      </Backdrop>
    </div>
  );
}
